* {
  font-family: 'Lato', sans-serif;
}
body {
  margin: 0;
}

.bg-w {
  background-color: #fff;
}

@media only screen and (min-width: 1024px) {
  body {
    background-color: #f5f5f5;
  }
  .bg-w {
    padding: 0 50px;
  }
  #__next {
    margin: 0 200px;
  }
}

@media only screen and (min-width: 1024px) {
  /* #__next {
    padding: 0 10px;
  } */
}
